/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
 

// core components
import Button from "@material-ui/core/Button";

import headerLinksStyle from "./HeaderStyle.jsx";
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import whatsappIcon from "../img/whatsappIcon.svg";


class HeaderRightLinks extends React.Component {

goToCareersPage = () =>{
  this.props.history.push("/careers")
}

openWhatsapp = () =>{
  var win = window.open("https://wa.me/918328112020?text=Hi,%20I'd%20like%20to%20know%20more%20about%20MinuteMen", '_blank');
  win.focus();
}

render(){
  const { classes,goToProductSection,goToResearchSection} = this.props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={goToProductSection}
          className={classes.navLink}
          name="products"
        >
         Products
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={goToResearchSection}
          className={classes.navLink}
          name="research"
        >
        Research
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={this.goToCareersPage}
          className={classes.navLink}
          name="research"
        >
        Careers
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={this.openWhatsapp}
          name="about"
          style={{marginTop: "-2px"}}
        >

        <img name="whatsapp" src={whatsappIcon}  style={{height: "25px", width: "25px", marginRight: "4px"}}/>
             8328112020
        </Button>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={handleHeaderLinkClick}
          className={classes.navLink}
          name="about"
        >
        About
        </Button>
      </ListItem>
     <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={handleHeaderLinkClick}
          className={classes.navLink}
          name="careers"
        >
        Careers
        </Button>
      </ListItem>*/}
    </List>
  );
}
}

export default withStyles(headerLinksStyle)(HeaderRightLinks);
