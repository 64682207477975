import React from "react";
import Header from "./Header.jsx";
import HeaderRightLinks from "./HeaderRightLinks.jsx";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import assesscore_logo from "../img/assesscore_black.png";
import classzero_logo from "../img/classzero_logo.svg";
import metaread_logo from "../img/metaread_logo.png";
import cite_logo from "../img/cite_logo.png";

import CiteVideoDialog from "./CiteVideoDialog.jsx";
import MetaReadDialog from "./MetaReadDialog.jsx"

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {roles} from '../data/careerRoles';
import CareerJobRole from './CareerJobRole.jsx';

class Careers extends React.Component {

  	constructor(props) {
        super(props)
  	}

  	componentDidMount() {
	  window.scrollTo(0,0);
	}

	goToProductSection = () => {
  		this.props.history.push("/")
  	}

  	goToResearchSection = () => {
  		this.props.history.push("/")
  	}

  render() {
    return (
    	<React.Fragment>
    	<div  style={{backgroundColor: "#f7f9fa",
  			backgroundImage: "url('https://static1.squarespace.com/static/ta/573a589c86db43d036604426/1028/assets/sm-hero-background-plain-grid.png')",
  			backgroundSize: "cover",
  			backgroundPosition: "50% 0%",
  			}}>
          	<Header 
          		rightLinks={<HeaderRightLinks 
          			goToProductSection={this.goToProductSection}
          			goToResearchSection={this.goToResearchSection}
          			{...this.props}
         			/>}
          		color="transparent"
          		changeColorOnScroll={{
		            height: 50,
		            color: "white"
		          }} 
		         {...this.props} 
          	/>
          	<div style={{paddingTop: "10em", paddingBottom: "4em", backgroundColor: "#f7f9fab3",
      			backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,0) 20%,rgba(255,255,255,.4) 100%,#fff 100%),radial-gradient(ellipse at center,rgba(247,249,250,.7) 0%,rgba(247,249,250,0) 60%),linear-gradient(to bottom,rgba(247,249,250,0) 0%,#f7f9fa 100%)"}}>
			    <Typography style={{paddingTop: "0em",textAlign: "center", margin: "0 auto",width: "80%"}} 
	          	variant="h4" gutterBottom>
			        Be part of our new AI-EdTech journey
			    </Typography>
			    <Typography style={{paddingTop: "1em",textAlign: "center", margin: "0 auto", width: "80%"}} 
	          	variant="subtitle1" gutterBottom>
			       We work with Educators and EdTech service providers and leverage our expertise in state-of-the-art technologies to improve their work-flows and operations.
			    </Typography>
          	</div>
      	</div>
		<div style={{padding: "30px 30px 0px 30px"}}>
	  		<Grid container spacing={0} justify="center" alignItems="center" >
		  		<Grid item style={{padding: "20px 20px 0px 20px"}} sm={12} md={7}>
		  			
		          	<Typography style={{marginTop: "-10px"}} 
		          	variant="h5" gutterBottom>
				       Open Roles
				    </Typography>
				    <Typography  
		          	variant="body1" gutterBottom>
		          		We're currently looking for help in the following areas
				    </Typography>
		  		</Grid>
	  		</Grid>
  		</div>
      	<div style={{padding: "15px 30px", minHeight: "10vh"}}>
	  		<Grid container direction="row" spacing={0} justify="center" alignItems="center" >
		  		{ roles.map((role, key) => 
			  		<Grid item style={{padding: "20px 0px"}} sm={12} md={7}>
			  			<CareerJobRole role={role}/>
			  		</Grid>
		  		)}
	  		</Grid>
  		</div>
  		<div style={{padding: "30px"}}>
  			<Typography style={{textAlign: "center"}}
          	variant="body1" display="block" gutterBottom>
          		Connect with us at <a href="mailto:careers@minutemen.in">careers@minutemen.in</a>
		    </Typography>
			<Typography style={{textAlign: "center"}}
          	variant="caption" display="block" gutterBottom>
		        &copy; Copyright {1900 + new Date().getYear()} Minutemen Innovations Pvt. Ltd, All Rights Reserved.
		    </Typography>
  		</div>
  		</React.Fragment>
    );
  }
}

export default Careers;
