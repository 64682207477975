import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import headerStyle from "./HeaderStyle.jsx";
import logo from "../img/logo_black.png";

class Header extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      mobileOpen: false,
	      brand: "",
	      whiteHeaderBorderPresent: false
	    };
	    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
	    this.headerColorChange = this.headerColorChange.bind(this);
	}
	handleDrawerToggle() {
	    this.setState({ mobileOpen: !this.state.mobileOpen });
	}
	componentDidMount() {
	    if (this.props.changeColorOnScroll) {
	      window.addEventListener("scroll", this.headerColorChange);
	    }
	}
	headerColorChange() {
	    const { classes, color, changeColorOnScroll, brandDefault} = this.props;
	    const windowsScrollTop = window.pageYOffset;
	    if (windowsScrollTop > changeColorOnScroll.height) {
	      document.body
	        .getElementsByTagName("header")[0]
	        .classList.add(classes["white"]);
	    } else {
	      document.body
	        .getElementsByTagName("header")[0]
	        .classList.remove(classes["white"]);
	    }

	    if(windowsScrollTop > 100 && windowsScrollTop < changeColorOnScroll.height){
	      this.setState({whiteHeaderBorderPresent: true})
	    } else{
	      this.setState({whiteHeaderBorderPresent: false})
	    }

	}
	componentWillUnmount() {
	    if (this.props.changeColorOnScroll) {
	      window.removeEventListener("scroll", this.headerColorChange);
	    }
	}

  	render() {
  		const {
	      classes,
	      rightLinks,
	      leftLinks,
	      color,
	      goToLandingPage
	    } = this.props;
	    
	    const appBarClasses = classNames({
	      [classes.appBar]: true,
	      [classes[color]]: color,
	      [classes.fixed]: true
	    });

	    const brandComponentLogo = (
	        <Grid container>
	        <Grid item lg={12} style={{marginLeft: "0px", marginRight: "0px"}}>
	            <img
	              onClick={goToLandingPage}
	              name="home"
	              src={logo}
	              alt="..."
	              style={{maxHeight: "70px", maxWidth: "90%", position: "relative"}}
	            />
	        </Grid>
	        </Grid>
	    );

	    return (
	      	<div>
	          	<AppBar className={ this.state.whiteHeaderBorderPresent ? classNames(appBarClasses,classes.borderBottom) : appBarClasses}>
		        <Toolbar className={classes.containerCustom}>
		            {leftLinks !== undefined ? brandComponentLogo : null}
		            <div className={classes.flex}>
		              {leftLinks !== undefined ? (
		                <Hidden smDown implementation="css">
		                  {leftLinks}
		                </Hidden>
		              ) : (
		                brandComponentLogo
		              )}
		            </div>
		            <Hidden smDown implementation="css">
		              {rightLinks}
		            </Hidden>
		            <Hidden mdUp>
		              <IconButton
		                color="inherit"
		                aria-label="open drawer"
		                onClick={this.handleDrawerToggle}
		              >
		                <Menu />
		              </IconButton>
		            </Hidden>
		        </Toolbar>
	          	<Hidden mdUp implementation="css">
		            <Drawer
		              variant="temporary"
		              anchor={"right"}
		              open={this.state.mobileOpen}
		              classes={{
		                paper: classes.drawerPaper
		              }}
		              onClose={this.handleDrawerToggle}
		               onClick={this.handleDrawerToggle}
		            >
		              <div className={classes.appResponsive}>
		                <div >
		                {leftLinks}
		                </div>
		                <div >
		                {rightLinks}
		                </div>
		              </div>
	            	</Drawer>
	          	</Hidden>
	       	</AppBar>
	      </div>
	    );
  	}
}

export default withStyles(headerStyle)(Header);
