import React from "react";
import Header from "./Header.jsx";
import HeaderRightLinks from "./HeaderRightLinks.jsx";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import assesscore_logo from "../img/assesscore_black.png";
import classzero_logo from "../img/classzero_logo.svg";
import metaread_logo from "../img/metaread_logo.png";
import cite_logo from "../img/cite_logo.png";

import CiteVideoDialog from "./CiteVideoDialog.jsx";
import MetaReadDialog from "./MetaReadDialog.jsx"

class LandingPage extends React.Component {

  constructor(props) {
        super(props)
        this.state = {
		  	citeVideoOpen: false,
		  	metaReadInfoOpen: false
		}
        this.productSectionRef = React.createRef() 
        this.researchSectionRef = React.createRef()  
    }

   componentDidMount() {
	  window.scrollTo(0,0);
	}

  goToAssessCore = () =>{
  	var win = window.open("https://assesscore.in", '_blank');
    win.focus();
  }

  goToClassZero = () =>{
  	var win = window.open("https://play.google.com/store/apps/details?id=com.classzero.live", '_blank');
    win.focus();
  }

  openCiteVideoDialog = () =>{
  	this.setState({citeVideoOpen: true})
  }

  closeCiteVideoDialog = () =>{
  	this.setState({citeVideoOpen: false})
  }

  openMetaReadDialog = () =>{
  	this.setState({metaReadInfoOpen: true})
  }

  closeMetaReadDialog = () =>{
  	this.setState({metaReadInfoOpen: false})
  }

  goToProductSection = () => {
  	window.scrollTo({top: this.productSectionRef.current.offsetTop-60, behavior: 'smooth'} )
  }

  goToResearchSection = () => {
  	 window.scrollTo({top: this.researchSectionRef.current.offsetTop-60, behavior: 'smooth'} )
  }

  render() {
    return (
    	<React.Fragment>
      	<div  style={{backgroundColor: "#f7f9fa",
  			backgroundImage: "url('https://static1.squarespace.com/static/ta/573a589c86db43d036604426/1028/assets/sm-hero-background-plain-grid.png')",
  			backgroundSize: "cover",
  			backgroundPosition: "50% 0%",
  			}}>
          	<Header 
          		rightLinks={<HeaderRightLinks 
          			goToProductSection={this.goToProductSection}
          			goToResearchSection={this.goToResearchSection}
          			{...this.props}
         			/>}
          		color="transparent"
          		changeColorOnScroll={{
		            height: 50,
		            color: "white"
		          }}
		         {...this.props}  
          	/>
          	<div style={{paddingTop: "12em", paddingBottom: "6em", backgroundColor: "#f7f9fab3",
      			backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,0) 20%,rgba(255,255,255,.4) 100%,#fff 100%),radial-gradient(ellipse at center,rgba(247,249,250,.7) 0%,rgba(247,249,250,0) 60%),linear-gradient(to bottom,rgba(247,249,250,0) 0%,#f7f9fa 100%)"}}>
	          	<Typography style={{textAlign: "center", fontWeight: "900",margin: "0 5%"}} 
	          	variant="h3" gutterBottom>
			       Elevating Education Through Innovation
			    </Typography>
			    <Typography style={{paddingTop: "1em",textAlign: "center", margin: "0 5%"}} 
	          	variant="subtitle1" gutterBottom>
			       We Create Solutions for the Super-Problems of the New Generation Educators
			    </Typography>
			    <div style={{paddingTop: "1em",margin: "auto", width: "fit-content"}}>
			    	<Button 
			    	onClick={this.goToProductSection}
			    	variant="contained" color="primary" style={{backgroundColor: "#35878f", borderRadius: "20px", padding: "8px 24px"}}>
			    		Discover Products
			    	</Button>
			    </div>
          	</div>
      	</div>
      	<div ref={this.productSectionRef} style={{padding: "30px", minHeight: "30vh"}}>
	  		<Grid container spacing={0} justify="center" alignItems="center" >
	  		<Grid item style={{padding: "20px"}} sm={12} md={4}>
	  			<img
	              name="home"
	              src={assesscore_logo}
	              alt="..."
	              style={{maxHeight: "70px", marginLeft: "-3%", maxWidth: "90%", position: "relative"}}
	            />
	          	<Typography style={{marginTop: "-10px"}} 
	          	variant="h5" gutterBottom>
			       Assessment like never before
			    </Typography>
			    <Typography  
	          	variant="body1" gutterBottom>
			       All that educators need to automate every step of assessment. Designed to save time, effort and cost involved in low-value adding operations.
			    </Typography>
			    <div style={{paddingTop: "1em", width: "fit-content"}}>
			    	<Button 
			    	onClick={this.goToAssessCore}
			    	variant="contained" color="primary" style={{backgroundColor: "green", borderRadius: "20px", padding: "8px 24px"}}>
			    		Explore AssessCore
			    	</Button>
			    </div>
	  		</Grid>
	  		{/*<Grid sm={12} md={4}>
	  		</Grid>
	  		</Grid>
  		</div>
  		<div style={{padding: "30px", minHeight: "30vh", backgroundColor: "#f7f9fa"}}>
	  		<Grid container spacing={12} justify="space-around">*/}
	  		<Grid item style={{padding: "20px"}} sm={12} md={4}>
	  			<img
	              name="home"
	              src={classzero_logo}
	              alt="..."
	              style={{maxHeight: "90px", marginLeft: "-2%" ,maxWidth: "90%", position: "relative"}}
	            />
	          	<Typography 
	          	variant="h5" gutterBottom>
			       Online Class Management At Zero Cost
			    </Typography>
			    <Typography  
	          	variant="body1" gutterBottom>
	          	   Forever Free Platform for educators to host online classes, share learning resources and effectively colloborate with their students.
			    </Typography>
			    <div style={{paddingTop: "1em", width: "fit-content"}}>
			    	<Button 
			    	onClick={this.goToClassZero}
			    	variant="contained" color="primary" style={{backgroundColor: "#df9a2a", borderRadius: "20px", padding: "8px 24px"}}>
			    		Explore ClassZero
			    	</Button>
			    </div>
	  		</Grid>
	  		{/*<Grid sm={12} md={4}>
	  		</Grid>*/}
	  		</Grid>
  		</div>
  		<div ref={this.researchSectionRef} style={{padding: "30px", minHeight: "30vh",backgroundColor: "#f7f9fa"}}>
	  		<Grid container spacing={0} justify="center" alignItems="center" >
	  		<Grid item style={{padding: "20px"}} sm={12} md={4}>
	  			<img
	              name="home"
	              src={metaread_logo}
	              alt="..."
	              style={{maxHeight: "70px", marginLeft: "-3%", maxWidth: "90%", position: "relative"}}
	            />
	          	<Typography style={{marginTop: "10px"}}
	          	variant="h5" gutterBottom>
			       Rapid Data Automation Intelligence
			    </Typography>
			    <Typography  
	          	variant="body1" gutterBottom>
	          		Designed to help Academia around the globe. State-of-the-art technology to accurately process, analyze and extract data from Books and other materials into Web compatible formats with zero manual intervention.
			    </Typography>
			    <div style={{paddingTop: "1em", width: "fit-content"}}>
			    	<Button 
			    		onClick={this.openMetaReadDialog}
			    		variant="contained" color="primary" style={{backgroundColor: "rgb(34, 56, 69)", borderRadius: "20px", padding: "8px 24px"}}>
			    		Explore MetaRead
			    	</Button>
			    	<MetaReadDialog open={this.state.metaReadInfoOpen} onClose={this.closeMetaReadDialog} />
			    </div>
	  		</Grid>
	  		{/*<Grid sm={12} md={4}>
	  		</Grid>
	  		</Grid>
  		</div>
  		<div style={{padding: "30px", minHeight: "30vh", backgroundColor: "#f7f9fa"}}>
	  		<Grid container spacing={12} justify="space-around">*/}
	  		<Grid item style={{padding: "20px"}}  sm={12} md={4}>
	  			<img
	              name="home"
	              src={cite_logo}
	              alt="..."
	              style={{maxHeight: "70px", marginLeft: "-2%" ,maxWidth: "90%", position: "relative"}}
	            />
	          	<Typography 
	          	variant="h5" gutterBottom>
			       Social Distance Assistant
			    </Typography>
			    <Typography  
	          	variant="body1" gutterBottom>
			       CITE is created to help businesses face the post covid world challenges.
It is designed to protect the health of your customers, workforce and improve their confidence to return.
			    </Typography>
			    <div style={{paddingTop: "1em", width: "fit-content"}}>
			    	<Button 
			    		onClick={this.openCiteVideoDialog}
			    		variant="contained" color="primary" style={{backgroundColor: "rgb(239 81 54)", borderRadius: "20px", padding: "8px 24px"}}>
			    		Explore CITE
			    	</Button>
			    	<CiteVideoDialog open={this.state.citeVideoOpen} onClose={this.closeCiteVideoDialog} />
			    </div>
	  		</Grid>
	  		{/*<Grid sm={12} md={4}>
	  		</Grid>*/}
	  		</Grid>
  		</div>
  		<div style={{padding: "30px"}}>
  			<Typography style={{textAlign: "center"}}
          	variant="body1" display="block" gutterBottom>
          		Connect with us at <a href="mailto:ai@minutemen.in">ai@minutemen.in</a>
		    </Typography>
			<Typography style={{textAlign: "center"}}
          	variant="caption" display="block" gutterBottom>
		       &copy; Copyright {1900 + new Date().getYear()} Minutemen Innovations Pvt. Ltd, All Rights Reserved.
		    </Typography>
  		</div>
  		</React.Fragment>
    );
  }
}

export default LandingPage;
