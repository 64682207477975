


const headerStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    borderRadius: "3px",
    //padding: "0.625rem 0",
    padding: "0 0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fff",
    //boxShadow:
      //"0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset",
    height: "60px"
  },
  borderBottom:{
    borderBottom: "1px solid rgb(218, 218, 218)",
    paddingTop: "10px !important"
  },
  absolute: {
    position: "absolute"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  containerCustom: {
    //paddingRight: "15px",
    //paddingLeft: "15px",
    //marginRight: "2em",
    //marginLeft: "2em",
    width: "100%",
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-around",
    display: "flex",
    flexWrap: "nowrap",
    //backgroundColor: "rgba(148, 152, 202, 0.2)"
  },
  container: {
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  flex: {
    //flex: 1
  },
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px"
  },
   appTheme: {
    color: "#FFFFFF",
    backgroundImage: "linear-gradient(0deg, #338537, #75c278)",
    //boxShadow:
     // "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  transparent: {
    //border: "0",
    //padding: "0.625rem 0",
    padding: "0 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "transparent",
    boxShadow: "none",
    paddingTop: "48px"
    //boxShadow:
    //  "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  white: {
    //border: "0",
    //padding: "0.625rem 0",
    padding: "0 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "white",
    boxShadow: "none",
    paddingTop: "48px",
    paddingBottom: "36px"
    //boxShadow:
    //  "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    //...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    maxHeight: "1200px",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
  },
  list: {
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    //paddingLeft: "20px",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "200px" 
    }
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  headerRightLinkslistItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    //fontWeight: "600",
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      //color: "black",
      background: "none",
      textDecoration: "underline"
      //background: "rgb(201, 214, 221)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.2rem",
      marginLeft: "10px",
      marginRight: "10px"
    }
  },

});

export default headerStyle;
