import React from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class CareerJobRole extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
        	expandJob: false
        }
  	}

  	expandRole = () => {
  		this.setState({expandJob: !this.state.expandJob})
  	}

  	applyJob(link){
  		var win = window.open(link, '_blank');
  		win.focus();
  	}

  	render() {
  	const {role} = this.props;
    return (
    	<React.Fragment>
  			<Card variant="outlined" style={{width: "100%"}}>
	  			<CardContent style={{paddingBottom: "16px"}}>
		  			<div style={{padding: "20px"}}>
			          	<Typography style={{marginTop: "-10px"}} 
			          	variant="h6" gutterBottom>
					       {role.title}
					    </Typography>
					    <Typography
			          	variant="body2" gutterBottom>
					       {role.subtitle}
					    </Typography>
					    {role.moreDetails && 
						    <Typography style={{marginTop: "10px"}}
				          	variant="body2" gutterBottom>
						       {role.moreDetails}
						    </Typography>
					    }
					    {this.state.expandJob &&
						    <div id="roleDetails">
							    {role.qualifications.length > 0 &&
								    <Typography  style={{marginTop: "20px"}}
						          	variant="body1" gutterBottom>
						          	Preferred Qualifications
								    <ul>
								    	{role.qualifications.map((qualification, key) => 
								    		<li>
								    			{qualification}
						          			</li>
								    	)}
						          	</ul>
						          	</Typography>
					          	}
					          	{role.experience.length > 0 &&
								    <Typography  style={{marginTop: "20px"}}
						          	variant="body1" gutterBottom>
						          	Preferred Experience
								    <ul>
								    	{role.experience.map((experience, key) => 
								    		<li>
								    			{experience}
						          			</li>
								    	)}
						          	</ul>
						          	</Typography>
					          	}
					          	{role.engagement.length > 0 &&
								    <Typography  style={{marginTop: "20px"}}
						          	variant="body1" gutterBottom>
						          	Perks
								    <ul>
								    	{role.engagement.map((engagement, key) => 
								    		<li>
								    			{engagement}
						          			</li>
								    	)}
						          	</ul>
						          	</Typography>
					          	}
				          	</div>
			          	}
					    <div style={{paddingTop: "1em", width: "fit-content"}}>
					    	<Button 
					    	onClick={this.expandRole}
					    	variant="contained" color="primary" style={{backgroundColor: "rgb(53, 135, 143)", borderRadius: "20px", padding: "8px 24px", margin: "5px"}}>
					    		{this.state.expandJob ? "Hide Details" : "Learn More"}
					    	</Button>
					    	<Button 
					    	onClick={(e) => {this.applyJob(role.applicationLink)}}
					    	variant="contained" color="primary" style={{backgroundColor: "green", borderRadius: "20px", padding: "8px 24px", margin: "5px"}}>
								Apply Now
					    	</Button>
					    </div>
					</div>    
			    </CardContent>
		    </Card>
    	</React.Fragment>
    )}
}

export default CareerJobRole;
