export const roles  = [
{
  "title": "UI/UX Design Internship",
  "subtitle": " Design | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Expertise in developing Information Architecture, improving Usability and Visual Language",
    "Ability to build end-to-end user flows for a highly scalable SaaS Product",
    "Ability to build Responsive Design for Web and Mobile App",
    "Experience with Material Design, Components & Interfaces",
    "To quickly learn, understand and re-think existing product and user interactions",
    "Experience with Adobe Creative Cloud, Figma, or similar tools",
  		"Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or portfolio projects (Academia) is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development "
  ],
  "applicationLink": "https://www.linkedin.com/jobs/view/2662479643/"
},
{
  "title": "Brand/Graphic Design Internship",
  "subtitle": " Design | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Expertise in creating seamless visual concepts that attract potential customers and improve brand visibility",
    "Ability to build visual content and illustrations for various marketing and branding campaigns",
    "Ability to build visual designs for Website, Social media, Emails, Blogs, Posters, and other marketing/branding material",
    "Experience with Adobe Creative Cloud, Photoshop, or similar tools",
  		"Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months ",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development"
  ],
  "applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "Content Operations Specialist (On-Demand/Part-Time)",
  "subtitle": " Operations | Part-Time | Remote | 2 Months (Extendable)",
  "moreDetails": "You will be involved in managing content operations such as digitizing question papers online using our AI toolset, reviewing question paper content, scheduling question papers, generating results, and more",
  "qualifications": [
    "Ability to understand and deal with Academic Content",
    "Knowledge of Tex/LaTex would be useful",
  ],
  "experience": [
    " Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote job for a minimum period of 2 months",
    "Candidates can choose to be available on need basis or from Thursday to Sunday every week",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development"
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "Mobile/Android App Development Internship",
  "subtitle": " Product Development | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Expertise in developing, debugging, testing and deployment of production-grade Native Android Apps",
    "Expertise in Kotlin, Java, MVVM Architecture, latest Kotlin features such as KMM, Android Studio, Firebase, and more",
    "Ability to quickly build and customize advanced functional modules, services, events, UI elements, layouts, and styles in Android",
    "Must know and follow production-grade code practices and technical design patterns",
    "Basic Knowledge of PSR (Performance, Stability, Reliability), Unit testing, and Test Automation tools",
    "Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development "
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "UI/Frontend/ReactJS Development Internship",
  "subtitle": " Product Development | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Expertise in developing, debugging, testing, and deployment of production-grade ReactJS based Responsive Web Applications",
    "Expertise in ReactJS, Webpack, Material UI, Redux, ES6, latest ReactJS features, and more",
    "Ability to quickly build and customize advanced functional modules, services, events, UI elements, layouts, and styles in ReactJS based web apps",
    "Must know and follow production-grade code practices and technical design patterns",
    "Basic Knowledge of PSR (Performance, Stability, Reliability) tasks, Unit testing, and Test Automation tools such as Selenium and Cypress",
    "Knowledge of Server Side Rendering and SEO is a plus",
    "Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development "
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "Full Stack/Backend Development Internship",
  "subtitle": " Product Development | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Expertise in developing, debugging, testing, and deployment of production-grade full-stack or server-side Applications",
    "Expertise in Ruby on Rails, MySQL, AWS, Microservices, Python, MVC Architecture, API-only backend, and more",
    "Ability to quickly build advanced algorithms and logical modules in Ruby on Rails and Python environments",
    "Must know and follow production-grade code practices and technical design patterns",
    "Basic Knowledge of PSR (Performance, Stability, Reliability) and Unit/Load/Stress Testing",
    "Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development "
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "AI/Deep Learning Internship",
  "subtitle": " Research | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Hands-on experience with Mask R-CNN, Faster R-CNN, ResNet, Transformers, GPT-3 based networks, Unified Language Models such as UniLM, Computer Vision Systems",
    "Should have built, trained, tested, debugged, and deployed end-to-end AI systems",
    "Hands-on with OpenCV, Tesseract OCR, Jupyter Notebook, Google Collab, Google Cloud Platform, Tensorflow, PyTorch, and other tools",
    "Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or research projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development "
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
},
{
  "title": "Multi-functional (PM/HR/Delivery) Management Internship",
  "subtitle": " Management | Internship | Remote | 2 Months (Extendable)",
  "qualifications": [
    "Proven expertise in Product Management and HR Management",
    "Ability to drive product strategy of the company by working closely with product teams to plan, prioritize and manage product deliverables",
    "Experience in Agile/Scrum methodologies and JIRA Management software",
    "Ability to run end-to-end hiring campaigns, on-board, and train candidates, improve HR processes and efficiency",    
    "Must have Critical thinking, Problem-solving, Number crunching, Communication skills, Delivery Management, and Multitasking abilities",
    "Interns will be required to spend at least 30 hours per week",
  ],
  "experience": [
    "Past experience in relevant work or projects is mandatory",
    "Previous experience in B2B products or Edtech is a plus"
  ],
  "engagement": [
    "This is a paid and remote internship for a minimum period of 2 months",
    "The internship period can be extended based on your performance and willingness",
    "A chance to experience a dynamic start-up environment with lots of room for learning and development"
  ],
		"applicationLink": "https://forms.gle/TXjMRM51GYDUENmt5"
}
]