import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

export default function MetaReadDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    	<DialogTitle>
    	<Typography variant="h6">MetaRead</Typography>
    	{onClose ? (
        <IconButton style={{position: "absolute", right: "5px", top: "5px"}} aria-label="close" onClick={onClose}>
	          <CloseIcon />
	        </IconButton>
	      ) : null}
    	</DialogTitle>
    	<DialogContent>
          <DialogContentText>
            Our interaction with more than a thousand modern educators has inspired us to think about one of their greatest challenges - 
          	Painstaking effort in moving their Content Online
          </DialogContentText>
          <DialogContentText>
            MetaRead AI is capable of converting offline content to online with zero human intervention in most practical cases.
            It includes inbuilt support for PDF's, Handwritten Notes, Mathematical Equations, Diagram handling and much more.  
          </DialogContentText>
          <DialogContentText>
            MetaRead is currently in Beta. 
            In Beta mode, MetaRead has helped some of our select customers in reducing
            70% of Human effort
            90% of Time and
            50% of Net Cost.
          </DialogContentText>
          <DialogContentText>
          	More details about MetaRead will be published soon.
          </DialogContentText>
          <DialogContentText>
          	Want to know more about MetaRead? Contact us at ai@minutemen.in 
          </DialogContentText>
        </DialogContent>   	
    </Dialog>
  );
}