import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import LandingMain from './components/LandingMain'
import Careers from './components/Careers.jsx'


function App() {
  return (
    <Router>
      <div>
        <Route exact path='/' 
            render={(routeProps) => (
            <LandingMain {...routeProps}/>
          )}  />
        <Route exact path='/careers' 
            render={(routeProps) => (
            <Careers {...routeProps}/>
          )}  />
      </div>
    </Router>
  );
}

export default App;
